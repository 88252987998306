export default {
  entityIdErrors () {
    const errors = []
    if (!this.$v.item.entityId.$dirty) return errors
    !this.$v.item.entityId.required && errors.push('Selecione um terminal.')
    return errors
  },
  nrJogoErrors () {
    const errors = []
    if (!this.$v.item.nrJogo.$dirty) return errors
    !this.$v.item.nrJogo.required && errors.push('O nrJogo é obrigatório.')
    return errors
  },
  codBilheteErrors () {
    const errors = []
    if (!this.$v.item.codBilhete.$dirty) return errors
    !this.$v.item.codBilhete.required && errors.push('O código do bilhete é obrigatório.')
    return errors
  }
}
