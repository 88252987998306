<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo mb-3" v-translate>Cancelamentos</h1>

      <div class="box-form"  style="max-width: 600px;">
        <div class="abas">
          <v-tabs
            v-model="tab"
            background-color="#FFF"
            height="30px"
          >
            <v-tab
              href="#tab-1"
              class="abas-item"
            >
              {{ $gettext('Bilhetes') }}
            </v-tab>
          </v-tabs>  
        </div>
        <v-tabs-items v-model="tab">
          <v-tab-item
            value="tab-1"
          >
            <v-card flat>
              <v-form>
                <v-card-text class="modal-cadastro-form">
                  <v-row>
                    <v-col cols="12" class="modal-cadastro-form-col-imput">
                      <!-- <v-select :items="listaItensSelect"  :label="$gettext('Produtos')"></v-select> -->

                      <v-autocomplete
                        v-model="item.entityId"
                        :items="listaItensSelect"
                        :label="$gettext('Terminal')"
                        @input="$v.item.entityId.$touch()" 
                        @blur="$v.item.entityId.$touch()"
                        v-on:keyup="searchAutoComplete"
                        :search-input.sync="searchText"
                        :error-messages="entityIdErrors"
                        :loading="isLoadingAutoComplete" />
                    </v-col>

                    <!-- <v-col cols="12" class="modal-cadastro-form-col-imput">
                      <v-text-field :label="$gettext('Nr. Bilhete')"  v-model="item.loteria"/>
                    </v-col> -->
                    <v-col cols="12" class="modal-cadastro-form-col-imput">
                      <v-text-field
                        :label="$gettext('Código do Bilhete')"
                        v-model="item.codBilhete"
                        :error-messages="codBilheteErrors"
                        @input="$v.item.codBilhete.$touch()" 
                        @blur="$v.item.codBilhete.$touch()" />
                    </v-col>

                    <v-col cols="12">
                      <v-btn
                        type="submit"
                        :color="variables.colorPrimary"
                        @click.prevent="cancelarBilhete"
                        class="br-btn"
                        :loading="loading">Cancelar Bilhete</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </v-card>
          </v-tab-item>
        </v-tabs-items>      
      </div>

      <!-- <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn> -->
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required  } from 'vuelidate/lib/validators'
import moment from 'moment'

import { errorSnackbar, successSnackbar, currency } from '@/core/service/utils'
import validacao from './validacao'
import variables from '@/assets/styles/helpers/_variables.scss'
const namespaceStore = 'jogos'

export default {
  name: 'Cancelamento',
  mixins: [validationMixin],
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Nr. Jogo', value: 'nrJogo' },
      { align: 'start', class: 'table-header', text: 'Venda', value: 'entityName' },
      { align: 'start', class: 'table-header', text: 'Código', value: 'transmissionCod' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'value' },
      { align: 'start', class: 'table-header', text: 'dt. Registro', value: 'created' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ],
    debounce: null,
    tab: 'tab-1',
    isLoadingAutoComplete: false,
    modelAutoComplete: null,
    searchText: null,
    loading: false
  }),

  mounted () {
    this.obterItens({ _filter: { 'entityTypeId_In': '4' } })
  },

  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens', 'item']),
    ...mapGetters('entities', ['listaItensSelect']),
    ...validacao,
    variables: () => variables
  },

  methods: {
    ...mapActions(namespaceStore, ['buscarItem', 'deletarItem', 'getItem', 'getBilhete', 'limparItem']),
    ...mapActions('entities', ['obterItens']),

    searchAutoComplete(event) {
      switch (event.keyCode) {
        case 37: // Left 
        case 38: // Up 
        case 39: // Right 
        case 40: // Down 
        case 13: // Enter 
          return
      }
      
      clearTimeout(this.debounce)
      const vm = this
      this.debounce = setTimeout(() => {
        if (vm.searchText === null || vm.searchText.length === 0) {
          vm.pesquisar('')
          return
        }

        vm.pesquisar(vm.searchText)
      }, 600)
    },

    pesquisar (val) {
      this.isLoadingAutoComplete = true
      this.obterItens({ _filter: { 'entityTypeId_In': '4', "Entities:jsonData.cod_ilike":`%${val}%` }, pageSize: this.$store.getters.ItensPaginacao })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.isLoadingAutoComplete = false)
    },

    deletarBilhete () {
      if (this.$v.item.$invalid) { 
        this.$v.item.$touch()
        document.querySelector('.v-dialog').scroll({ top: 0, left: 0, behavior: 'smooth' })
        return false 
      }

      this.loading = true

      const dados = Object.assign({}, this.item)

      this.deletarItem(dados).then(() =>{
        successSnackbar('Item excluído com sucesso!')
      })
      .catch(err => {
        errorSnackbar(err.error)
      })
      .finally(() => {
        this.loading = false
      })
    },

    cancelarBilhete () {
      if (this.$v.item.$invalid) { 
        this.$v.item.$touch()
        document.querySelector('.v-dialog').scroll({ top: 0, left: 0, behavior: 'smooth' })
        return false 
      }

      this.loading = true

      const dados = Object.assign({}, this.item)

      dados.codBilhete = dados.codBilhete.toUpperCase()

      this.getBilhete(dados).then((result) =>{
        if (moment(result.data.resultMap.deleted).format('DD/MM/YYYY') !== '01/01/1970') {
          this.$swal({
            icon: 'error',
            text: `Bilhete com o código ${dados.codBilhete} no valor de ${currency(result.data.resultMap.value)}, já está excluído`,
            showConfirmButton: false,
            // confirmButtonText: 'Sim',
            // cancelButtonText: 'Não',
          })
          .finally(() => this.loading = false)

          return
        }

        this.$swal({
          icon: 'warning',
          text: `Tem certeza que deseja cancelar esse bilhete com o código ${dados.codBilhete} no valor de ${currency(result.data.resultMap.value)}?`,
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.value) {
            this.deletarItem(dados).then(() => {
              successSnackbar('Bilhete excluído com sucesso!')
              this.limparItem()
              this.$v.item.$reset()
              // vm.buscar()
            }).catch(err => {
              if (/soft-delete/.test(err.error)) {
                errorSnackbar(`Bilhete com o código ${dados.codBilhete}, já estava excluído!`)
                return
              }
              errorSnackbar(err.error)
            })
            .finally(() => {
              this.loading = false
            })
          } else {
            this.loading = false
          }
        })
      })
      .catch(err => {
        this.loading = false
        errorSnackbar(err.error)
      })
      
    }
  },

  validations () {
    return  {
      item: {
        entityId: { required },
        // nrJogo: { required },
        codBilhete: { required }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .box-form {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .3);
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }

  
.abas {
  margin-left: 10px;
  padding-top: 10px;
  .v-tab {
    font-size: 14px;
  }

  .abas-item {
    background-color: transparent;
    border: 1px solid $colorPrimary;
    color: $colorPrimary !important;
    padding-top: 2px;
    
    &:not(:last-child) {
      margin-right: 10px;
    }

    &:before {
      display: none;
    }

    &.v-tab--active {
      background-color: $colorPrimary;
      color: #FFF !important;
    }

  }
  .v-tabs .v-tabs-slider-wrapper {
    display: none;
  }
}
</style>>
